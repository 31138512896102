import PayIcon from '~/assets/imgs/deposit/pay-icon.png'
import type { PageRequest, PageResponse, Response } from '~/type'
import type { BankAddressDTO, CryptoAddressDTO, DepositConfigDTO, DepositOrderDTO, DiscountWalletRes, RebateOrderDTO, SupportBankDTO, SupportProtocolDTO, TradeRecordDTO, WithdrawConfigDTO, WithdrawOrderDTO, WithdrawTypeEnum } from '~/type/finance'
import { http } from '~/utils/http'

// 中心钱包余额
export function getCenterBalance() {
  return http<
    Response<{
      balance: string | number
    }>
  >('/app-api/account/memberAccount/center/balance', {
    method: 'POST',
    body: {}
  }).then((res) => {
    useState<number>('balance').value = Number(res.data?.balance) || 0
    return res
  })
}

export function getDiscountWallet() {
  return http<
    Response<DiscountWalletRes>
  >('/app-api/account/memberAccount/info', {
    method: 'POST',
    body: {}
  })
}

// 提款首页
export function getAccountWithdrawConfig() {
  return http<Response<WithdrawConfigDTO>>('/app-api/account/withdrawConfig/index', {
    method: 'POST',
    body: {}
  })
}

// 提交取款单
export function submitWithdraw(params: {
  // 提款类型 1_银行卡转账 2_虚拟币转账 3_位银行转账
  withdrawType: WithdrawTypeEnum
  // 提款金额
  amount: number
  // 取款密碼
  withdrawPassword: string
  // 提款账户ID，银行卡传绑卡记录ID，虚拟币传虚拟币地址ID
  bindAccountId: string
}) {
  return http<Response<WithdrawOrderDTO>>('/app-api/account/memberWithdraw/submit', {
    method: 'POST',
    body: params,
    noMsg: true
  })
}

// 存款页面 首页配置项
export async function getDepositConfig() {
  return http<Response<DepositConfigDTO>>('/app-api/account/depositConfig/index', {
    method: 'POST',
    body: {}
  }).then((res) => {
    res.data!.banners = [
      {
        title: 'EBPAY钱包',
        image: PayIcon,
        url: 'https://www.google.com'
      }
    ]
    res.data?.depositTypes?.forEach((mode) => {
      mode.iconUrl = addHttpPrefix(mode.iconUrl)
      mode.channelNameList?.forEach((channel) => {
        channel.amountMin = Number(channel.amountMin)
        channel.amountMax = Number(channel.amountMax)
        channel.exchangeRate = Number(channel.exchangeRate)
      })
    })
    return res
  })
}

// 提交存款订单
export async function submitDeposit(body: {
  // 通道id
  paymentChannelId: number
  // 金额
  amount: number
}) {
  return http<Response<DepositOrderDTO>>('/app-api/account/memberRecharge/submit', {
    method: 'POST',
    body
  })
}

//获取存款订单信息
export async function getDepositOrder(body: { orderNo?: string }) {
  return http<Response<DepositOrderDTO>>('/app-api/account/memberRecharge/info', {
    method: 'POST',
    body
  }).then((res) => {
    // 统一处理下数字格式
    res.data!.payAmount = Number(res.data!.payAmount!).toFixed(2)
    res.data!.amount = Number(res.data!.amount!).toFixed(2)
    res.data!.arrAmount = Number(res.data!.arrAmount!).toFixed(2)
    return res
  })
}

// 提交存款凭证
export async function submitDepositProof(body: {
  // 订单号
  orderNo?: string
  // 凭证
  evidence?: string
}) {
  return http<Response>('/app-api/account/memberRecharge/checkCode', {
    method: 'POST',
    body
  })
}

// 取消存款
export async function cancelDeposit(body: { orderNo?: string }) {
  return http<Response>('/app-api/account/memberRecharge/cancel', {
    method: 'POST',
    body
  })
}

// 银行卡列表
export async function getBankAddress(data: { bankType: number }) {
  return http<
    Response<{
      /*最大绑定 */
      total: number
      /*当前绑定 */
      current: number
      // 银行卡列表
      memberBanks: BankAddressDTO[]
    }>
  >('/app-api/account/memberBankAccount/memberBankList', {
    method: 'POST',
    body: data
  }).then((res) => {
    if (Array.isArray(res?.data?.memberBanks)) {
      res.data.memberBanks.forEach((item) => (item.logo = addHttpPrefix(item.logo)))
    }
    return res
  })
}

// 银行配置列表
export async function getSupportBank(data: { bankType: number }) {
  return http<Response<SupportBankDTO[]>>('/app-api/account/memberBankAccount/supportBank', {
    method: 'POST',
    body: data
  })
}

/**
 * 新增银行卡
 * @param {object} body 會員新增銀行卡DTO
 * @param {number} body.bankType 银行类型 1:普通银行 2:数位银行
 * @param {number} body.bankId 银行ID
 * @param {string} body.bankBranch 支行名称
 * @param {string} body.bankAccount 银行账号
 * @param {string} body.bankField 栏位
 * @param {string} body.remark 备注
 * @returns
 */
export async function addMemberBank(body: { bankType: number; bankId?: number; realName?: string; bankBranch?: string; bankAccount: string; bankField?: string; remark?: string }) {
  return http<Response>('/app-api/account/memberBankAccount/addMemberBank', {
    method: 'POST',
    body
  })
}

// 银行配置列表
export async function getChainTypeList(data: { currency: string }) {
  return http<Response<SupportProtocolDTO[]>>('/app-api/account/memberWithdrawAddr/chainType', {
    method: 'POST',
    body: data
  })
}

/**
 * 提币地址绑定
 * @param {object} body WithdrawAddressBindDTO
 * @param {number} body.chainTypeId 链类型ID
 * @param {string} body.address 虚拟币地址
 * @param {string} body.label 用户添加的地址标签
 * @param {string} body.withdrawPassword 取款密碼
 * @returns
 */
export async function withdrawAddressBind(body: { chainTypeId: number; address: string; label: string; withdrawPassword?: string }) {
  return http<Response>('/app-api/account/memberWithdrawAddr/withdrawAddressBind', {
    method: 'POST',
    body
  })
}

// 提币地址列表
export async function getUSDTAddress(data: { currency: string }) {
  return http<
    Response<{
      /*最大绑定 */
      total: number
      /*当前绑定 */
      current: number
      /*虚拟币地址列表 */
      memberWithdrawAddr: CryptoAddressDTO[]
    }>
  >('/app-api/account/memberWithdrawAddr/withdrawAddressList', {
    method: 'POST',
    body: data
  }).then((res) => {
    if (Array.isArray(res?.data?.memberWithdrawAddr)) {
      res.data.memberWithdrawAddr.forEach((item) => {
        item.icon = item.protocol == 'TRC20' ? getImage('withdraw_usdt_trc20') : getImage('withdraw_usdt_erc20')
      })
    }
    return res
  })
}

// 交易记录查询
export async function transactionRecordQuery(
  data: PageRequest<{
    // 状态 1:进行中 2:成功 3:取消
    status: number
    // 交易类型 1:存款 2:取款 3:活动 4返水 5佣金
    transactionType: number
    startDate: string
    endDate: string
  }>
) {
  return http<
    PageResponse<
      TradeRecordDTO,
      {
        // 总计金额
        totalAmount: string
        totalCnt: number
      }
    >
  >('/app-api/account/memberTransaction/query', {
    method: 'POST',
    body: data
  }).then(res=>{
    res.data.records.forEach(it=>{
      const {$i18n: {t}} = useNuxtApp()
      const { recordType, transactionType, ext } = it
      it.recordTypeText = (()=>{
        switch (recordType) {
          case 'EVERY_REBATE':
            return t('trade_record_cash_back_every_time_text')
          case 'Scratch_Card':
            return t('trade_record_scratch_card_text')
          case 'MANUAL_IN':
            return t('trade_record_manual_in_text')
          case 'MANUAL_OUT':
            return t('trade_record_manual_out_text')
          case 'BANK_TRANSFER':
            return transactionType === 2 ? t('trade_record_bank_withdraw_text') : t('trade_record_bank_transfer_text')
          case 'VIRTUAL_TRANSFER':
            return t('trade_record_virtual_transfer_text') + `${ext ? `(${ext})` : ''}`
          case 'DIGITAL_TRANSFER':
            return t('trade_record_digital_transfer_text')
          case 'ACTIVITY_DISCOUNT_IMPORT':
            return '优惠汇入'
          case 'ACTIVITY_DISCOUNT_DEDUCT':
            return '优惠冲销'
          case 'QUALITY_DISCOUNT_IMPORT':
            return '代理返佣'
          default:
            return recordType
        }
      })()
      it.statusProps = (()=>{
        let text = ''
        let color = ''
        switch (it.status) {
          case 1:
            text = t('trade_record_ing_text')
            color = getResource('global_main_color')
            break
          case 2:
            text = t('trade_record_finish_text')
            color = getResource('global_success_color')
            break
          case 3:
            text = t('trade_record_canceled_text')
            color = getResource('global_maintext_color')
            break
          case 4:
            text = t('trade_record_fail_text')
            color = getResource('global_error_color')
            break
        }
        return {
          text,
          color
        }
      })()
    })
    return res
  })
}

// 获取取款订单信息
export async function getWithdrawOrder(body: { orderNo?: string }) {
  return http<Response<WithdrawOrderDTO>>('/app-api/account/memberWithdraw/info', {
    method: 'POST',
    body
  }).then((res) => {
    // 统一处理下数字格式
    res.data!.payAmount = Number(res.data!.payAmount!).toFixed(2)
    res.data!.amount = Number(res.data!.amount!).toFixed(2)
    return res
  })
}

// 返水记录详情
export async function getRebateOrder(body: { rebateNo?: string }) {
  return http<Response<RebateOrderDTO>>('/app-api/activity/rebate/receiveRecordInfo', {
    method: 'POST',
    body
  }).then((res) => {
    return res
  })
}
